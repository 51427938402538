<template>
  <div class="app">
    <div class="controllers">
      <h3 style="margin: 0">Decision Tree selection</h3>
      <div class="filters">
        <label>Order By: </label>
        <button
          @click="setFilter('accuracy')"
          :class="{ 'active-filter': this.filter === 'accuracy', filter: true }"
        >
          Accuracy
        </button>
        <button
          @click="setFilter('f1')"
          :class="{ 'active-filter': this.filter === 'f1', filter: true }"
        >
          F1
        </button>
        <button
          @click="setFilter('precision')"
          :class="{
            'active-filter': this.filter === 'precision',
            filter: true,
          }"
        >
          Precision
        </button>
        <button
          @click="setFilter('recall')"
          :class="{ 'active-filter': this.filter === 'recall', filter: true }"
        >
          Recall
        </button>
        <button
          @click="setFilter('n_nodes')"
          :class="{ 'active-filter': this.filter === 'n_nodes', filter: true }"
        >
          Size
        </button>
      </div>
      <TreeSelector
        :possibleTrees="possibleTrees"
        @selectTree="selectTree"
        :selectedTree="selectedTree"
        :filter="filter"
        v-if="!gradient_boost"
      ></TreeSelector>
      <ClassifierTool
        :selectedTree="selectedTree"
        @classification="highlightBranch"
      ></ClassifierTool>
    </div>
    <div class="graphs">
      <div class="decision-tree-container">
        <div class="decision-tree-legend">
          Legend
          <div class="legend-element">
            <TreeNode
              content="Boolean Expression"
              backgroundColor="white"
              id="beTrue"
            ></TreeNode>
            <TreeNode
              content="Destination If True"
              backgroundColor="white"
              id="deTrue"
            ></TreeNode>
          </div>
          <div class="legend-element">
            <TreeNode
              content="Boolean Expression"
              backgroundColor="white"
              id="beFalse"
            ></TreeNode>
            <TreeNode
              content="Destination If False"
              backgroundColor="white"
              id="deFalse"
            ></TreeNode>
          </div>
        </div>
        <button class="orientation" @click="horizontal = !horizontal">
          Change orientation
        </button>
        <DecisionTree
          :tree="treeWithDescendantCount"
          v-if="tree && !gradient_boost"
          :colormap="colormap"
          :highlightedBranch="highlightedBranch"
          :horizontal="horizontal"
        ></DecisionTree>
      </div>
      <PlotlyWrapper
        :traces="data"
        :updateForcer="updateForcer"
      ></PlotlyWrapper>
    </div>
  </div>
</template>

<script>
import DecisionTree from "./components/DecisionTree.vue";
import PlotlyWrapper from "./components/PlotlyWrapper";
import axios from "axios";
import TreeSelector from "@/components/TreeSelector";
import ClassifierTool from "@/components/ClassifierTool";
import TreeNode from "@/components/TreeNode";
import LeaderLine from "leader-line-vue";

export default {
  name: "App",
  components: {
    TreeNode,
    ClassifierTool,
    TreeSelector,
    DecisionTree,
    PlotlyWrapper,
  },
  data() {
    return {
      tree: null,
      data: {},
      selectedTree: 0,
      colors: [
        "#e6194B",
        "#3cb44b",
        "#ffe119",
        "#4363d8",
        "#f58231",
        "#911eb4",
        "#42d4f4",
        "#f032e6",
        "#bfef45",
        "#fabed4",
        "#469990",
        "#dcbeff",
        "#9A6324",
        "#fffac8",
        "#800000",
        "#aaffc3",
        "#808000",
        "#ffd8b1",
        "#000075",
        "#a9a9a9",
        "#ffffff",
        "#000000",
      ],
      colormap: {},
      updateForcer: 0,
      highlightedBranch: null,
      possibleTrees: [],
      trueLine: null,
      falseLine: null,
      filter: "accuracy",
      gradient_boost: false,
      horizontal: false,
    };
  },
  computed: {
    treeWithDescendantCount: function () {
      const getNodeDescendantCount = (node) => {
        if (!node.left_child && !node.right_child) {
          return {
            ...node,
            descendantCount: 0,
          };
        } else {
          const newNode = {
            ...node,
            left_child: getNodeDescendantCount(node.left_child),
            right_child: getNodeDescendantCount(node.right_child)
          }
          return {
            ...newNode,
            descendantCount: newNode.left_child.descendantCount + newNode.right_child.descendantCount + 2
          }
        }
      };
      return getNodeDescendantCount(this.tree);
    },
  },
  methods: {
    setFilter: function (filterName) {
      this.filter = filterName;
    },
    highlightBranch: function (branch) {
      this.highlightedBranch = branch;
    },
    selectTree: function (id) {
      this.selectedTree = id;
      this.getInputFromAPI();
    },
    useDefaultTraces: function () {
      this.colormap = {};
      Object.keys(this.data).forEach((trace, index) => {
        const text = this.data[trace]["hearinglevel_left"].map(
          (value, index) => {
            return `Left hear loss: ${value}dB<br>Right hear loss: ${this.data[trace]["hearinglevel_right"][index]}dB`;
          }
        );
        this.colormap[trace] = this.colors[index];
        this.data[trace] = {
          ...this.data[trace],
          mode: "markers",
          type: "scatter",
          visible: "show",
          marker: {
            color: this.colormap[trace],
          },
          name: this.data[trace]["branch"],
          hovertemplate: "%{text}<extra></extra>",
          text: text,
        };
      });
    },
    createClickHandler: function (field) {
      let auxThis = this;
      return function () {
        auxThis.data[field] = {
          ...auxThis.data[field],
          visible:
            auxThis.data[field]["visible"] === "show" ? "legendonly" : "show",
        };
        auxThis.updateForcer++;
      };
    },
    setClickHandlers: function (tree) {
      if (!tree) return;
      if (!tree.left_child && !tree.right_child) {
        if (tree.content in this.data) {
          tree.clickHandler = this.createClickHandler(tree.content);
        }
      } else {
        if (tree.left_child) {
          this.setClickHandlers(tree.left_child);
        }
        if (tree.right_child) {
          this.setClickHandlers(tree.right_child);
        }
      }
    },
    getInputFromAPI: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/get_tree/${this.selectedTree}`)
        .then((response) => {
          this.data = response.data["point_mappings"];
          this.tree = response.data["tree"];
          this.useDefaultTraces();
          this.setClickHandlers(this.tree);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/get_trees`)
        .then((response) => {
          this.possibleTrees = response.data.metadata;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createLines: function () {
      if (this.trueLine) {
        this.trueLine.remove();
        this.trueLine = null;
      }
      if (this.falseLine) {
        this.falseLine.remove();
        this.falseLine = null;
      }
      this.trueLine = LeaderLine.setLine(
        document.getElementById("beTrue"),
        document.getElementById("deTrue"),
        {
          color: "black",
          size: 2,
          path: "straight",
          startSocket: "right",
          endSocket: "left",
          endPlug: "arrow1",
          endPlugSize: 2,
          dash: false,
        }
      );
      this.falseLine = LeaderLine.setLine(
        document.getElementById("beFalse"),
        document.getElementById("deFalse"),
        {
          color: "black",
          size: 2,
          path: "straight",
          startSocket: "right",
          endSocket: "left",
          endPlug: "arrow1",
          endPlugSize: 2,
          dash: true,
        }
      );
    },
  },
  created() {
    this.getInputFromAPI();
  },
  mounted() {
    this.createLines();
  },
  updated() {
    this.createLines();
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  padding: 1rem;
  border: 1px solid black;
  border-radius: 2rem;
  /* background: #FEECE9; */
  width: fit-content;
}

.controllers {
  width: 30%;
}

.graphs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.decision-tree-container {
  border: 1px solid black;
  border-radius: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.decision-tree-legend {
  border: 1px solid black;
  border-radius: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.legend-element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h3 {
  padding: 0;
  margin: 1rem 0.5rem;
}

.filters {
  margin: 1rem 0;
}

.filter {
  border: 1px solid black;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin: 0 0.2rem;
  cursor: pointer;
  font: inherit;
  background: #ccd1e4;
  color: #2f3a8f;
}

.active-filter {
  background: #fe7e6d;
  color: #feece9;
}

.orientation {
  font: inherit;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 1rem;
  background: #ccd1e4;
  border-radius: 2rem;
  border: 1px solid #2f3a8f;
  color: #2f3a8f;
}
</style>
