<template>
  <div>
    <h3 style="text-align: center">t-SNE Visualization</h3>
    <div id="myDiv"></div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";

export default {
  name: "PlotlyWrapper",
  props: ["traces", "updateForcer"],
  methods: {
    renderPlot() {
      const traces = [];
      for (let trace in this.traces) {
        traces.push(this.traces[trace]);
      }
      Plotly.newPlot("myDiv", []);
    },
    updatePlot() {
      const traces = [];
      for (let trace in this.traces) {
        traces.push(this.traces[trace]);
      }
      Plotly.newPlot("myDiv", traces);
    },
  },
  watch: {
    traces: function () {
      this.updatePlot();
    },
  },
  mounted() {
    this.renderPlot();
  },
  updated() {
    this.updatePlot();
  },
};
</script>

<style scoped>
#myDiv {
  width: 60rem;
  height: 40rem;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 2rem;
  box-sizing: content-box;
  margin: 2rem;
}
</style>
