<template>
  <div
    :class="{
      'decision-tree-horizontal': horizontal,
      'decision-tree-vertical': !horizontal,
    }"
    v-resize="update_arrows"
  >
    <template v-for="(row, level) in rows">
      <div
        v-if="
          !hideLevels.includes(level) &&
          (level === 0 || !isAnyLevelCollapsedAbove(level))
        "
        :class="{ column: horizontal, row: !horizontal }"
        :key="`${level}${hideLevels.includes(level)}`"
      >
        <button @click="hideLevels.push(level)">
          <FontAwesomeIcon icon="fa-solid fa-minus"></FontAwesomeIcon>
        </button>
        <template v-for="(node, idx) in row">
          <div
            class="node"
            v-if="
              !hiddenNodes.some(
                (element) => element.row === level && element.column === idx
              )
            "
            :key="(node && node.id) || idx"
          >
            <TreeNode
              v-if="!node"
              content="PLACEHOLDER"
              background-color="black"
              color="white"
              :highlightedBranch="highlightedBranch"
            ></TreeNode>
            <TreeNode
              v-else-if="
                !node.left_child && !node.right_child && !colormap[node.content]
              "
              :content="node.content"
              :id="'_' + node.id"
              background-color="black"
              color="white"
              :highlightedBranch="highlightedBranch"
            ></TreeNode>
            <TreeNode
              v-else-if="!node.left_child && !node.right_child"
              :content="node.content"
              :id="'_' + node.id"
              :background-color="colormap[node.content]"
              :cursor="'pointer'"
              :clickHandler="node.clickHandler"
              :highlightedBranch="highlightedBranch"
            ></TreeNode>
            <TreeNode
              v-else
              :content="node.content"
              :id="'_' + node.id"
              :highlightedBranch="highlightedBranch"
              background-color="white"
              :clickHandler="
                () => {
                  if (
                    this.collapseNodes.some(
                      (element) =>
                        element.row === level && element.column === idx
                    )
                  ) {
                    this.collapseNodes = [...this.collapseNodes].filter(
                      (element) =>
                        !(element.row === level && element.column === idx)
                    );
                  } else {
                    this.collapseNodes = [
                      ...this.collapseNodes,
                      { row: level, column: idx },
                    ];
                  }
                }
              "
              :collapsed="
                this.collapseNodes.some(
                  (element) => element.row === level && element.column === idx
                )
              "
              :descendantCount="node.descendantCount"
            ></TreeNode>
          </div>
        </template>
      </div>
      <template v-else>
        <div :class="{ column: horizontal, row: !horizontal }" :key="-level">
          <button @click="showLevel(level)">
            <FontAwesomeIcon icon="fa-solid fa-plus"></FontAwesomeIcon>
          </button>
          <span
            v-if="!horizontal"
            :style="{
              alignSelf: 'center',
              margin: '1rem',
              width: (row.length / rows.at(-1).length) * 100 + '%',
              height: (row.length / rows.at(-1).length) * 8 + 'px',
              background: '#CCD1E4',
              border: none,
            }"
          />
          <span
            v-else
            :style="{
              alignSelf: 'center',
              margin: '1rem',
              height: (row.length / rows.at(-1).length) * 100 + '%',
              width: (row.length / rows.at(-1).length) * 8 + 'px',
              background: '#CCD1E4',
              border: none,
            }"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import LeaderLine from "leader-line-vue";
import TreeNode from "./TreeNode.vue";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "DecisionTree",
  components: { TreeNode, FontAwesomeIcon },
  props: {
    tree: null,
    colormap: null,
    highlightedBranch: null,
    horizontal: Boolean,
  },
  data: function () {
    return {
      arrows: [],
      hideLevels: [],
      collapseNodes: [],
    };
  },
  computed: {
    rows: function () {
      // create full tree
      const rows = [];
      const queue = [{ node: this.tree, depth: 0 }];
      while (queue.length > 0) {
        const elem = queue.pop();
        if (rows.length == elem.depth) {
          const isAllNull = !(
            elem.node ||
            queue.reduce(
              (aggregate, current) => aggregate || current.node,
              false
            )
          );
          if (isAllNull) break;
          rows.push([]);
        }
        if (elem.node) {
          rows.at(-1).unshift(elem.node);
          if (elem.node.left_child) {
            queue.unshift({
              node: elem.node.left_child,
              depth: elem.depth + 1,
            });
          } else {
            queue.unshift({
              node: null,
              depth: elem.depth + 1,
            });
          }
          if (elem.node.right_child) {
            queue.unshift({
              node: elem.node.right_child,
              depth: elem.depth + 1,
            });
          } else {
            queue.unshift({
              node: null,
              depth: elem.depth + 1,
            });
          }
        } else {
          rows.at(-1).unshift(null);
          queue.unshift({
            node: null,
            depth: elem.depth + 1,
          });
          queue.unshift({
            node: null,
            depth: elem.depth + 1,
          });
        }
      }
      //remove hidden levels and superfluous nulls (nulls in a line right after an ommited line)
      this.hideLevels.forEach((line) => {
        if (line + 1 < rows.length) {
          rows[line + 1] = rows[line + 1].filter((value) => !!value);
        }
      });

      return rows;
    },
    hiddenNodes: function () {
      const hiddenNodes = [];
      const queue = [...this.collapseNodes];
      console.log(queue);
      while (queue.length > 0) {
        const elem = queue.pop();
        if (elem.row + 1 < this.rows.length) {
          const leftChild = { row: elem.row + 1, column: elem.column * 2 };
          const rightChild = { row: elem.row + 1, column: elem.column * 2 + 1 };
          hiddenNodes.push(leftChild);
          hiddenNodes.push(rightChild);
          queue.push(leftChild);
          queue.push(rightChild);
        }
      }
      return hiddenNodes;
    },
  },
  directives: {
    resize: {
      mounted: function (el, binding) {
        const onResizeCallback = binding.value;
        window.addEventListener("resize", () => {
          const width = document.documentElement.clientWidth;
          const height = document.documentElement.clientHeight;
          onResizeCallback({ width, height });
        });
      },
    },
  },
  methods: {
    isAnyLevelCollapsedAbove: function (level) {
      return _.range(level).reduce((aggregate, higherLevel) => {
        console.log("higherLevel -> ", higherLevel);
        console.log("collapsed nodes -> ", this.collapseNodes.filter((element) => element.row === higherLevel).length);
        console.log("possible nodes -> ", this.rows[higherLevel].filter((node) => node.left_child).length);
        return (
          aggregate 
          ||
            this.collapseNodes.filter((element) => element.row === higherLevel).length 
            >=
            this.rows[higherLevel].filter((node) => node.left_child).length
        );
      }, false);
    },
    showLevel: function (level) {
      const index = this.hideLevels.indexOf(level);
      console.log(index);
      this.hideLevels.splice(index, 1);
    },
    connect_nodes: function (parent, child, isLeft) {
      const start = document.getElementById("_" + parent.id);
      const end = document.getElementById("_" + child.id);
      if (start && end) {
        return LeaderLine.setLine(start, end, {
          color: "black",
          size: 2,
          path: "straight",
          startSocket: this.horizontal ? "right" : "bottom",
          endSocket: this.horizontal ? "left" : "top",
          endPlug: "arrow1",
          endPlugSize: 2,
          dash: isLeft,
        });
      }
      return null;
    },
    update_arrows: function () {
      this.arrows.forEach((arrow) => {
        if (arrow) {
          arrow.remove();
        }
      });
      this.arrows = [];
      const queue = [this.tree];
      while (queue.length > 0) {
        const node = queue.pop();
        if (node.left_child) {
          const arrow = this.connect_nodes(node, node.left_child, false);
          this.arrows.push(arrow);
          queue.unshift(node.left_child);
        }
        if (node.right_child) {
          const arrow = this.connect_nodes(node, node.right_child, true);
          this.arrows.push(arrow);
          queue.unshift(node.right_child);
        }
      }
    },
    collapseDefault: function () {
      const defaultHeightCollapse = 2;
      let collapseNodes = [];
      _.range(defaultHeightCollapse, this.rows.length).forEach((row) => {
        collapseNodes = collapseNodes.concat(
          _.range(2 ** row)
            .map((i) => ({
              row: row,
              column: i,
            }))
            .filter(
              (node) =>
                this.rows[node.row][node.column] &&
                this.rows[node.row][node.column].left_child
            )
        );
      });
      console.log("---------->", collapseNodes);
      this.collapseNodes = collapseNodes;
    },
  },
  watch: {
    tree: function () {
      this.collapseDefault();
    },
  },
  mounted() {
    this.$emit("mounted");
    this.update_arrows();
    this.collapseDefault();
  },
  updated() {
    this.update_arrows();
    // this.collapseDefault();
    console.log("---------------------");
    console.log(this.collapseNodes);
    console.log(this.hideLevels);
    console.log(this.rows);
    console.log("!!!!!!!!!!!!!!!!!!!!!");
  },
  unmounted() {
    this.arrows.forEach((arrow) => {
      arrow.remove();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.decision-tree-horizontal {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 2rem 0;
  margin: 1rem 0;
}

.column {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.decision-tree-vertical {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  margin: 0 1rem;
}

.row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}

.row button,
.collapse-button-row {
  background: #ccd1e4;
  height: 1rem;
  width: 1rem;
  text-align: center;
  padding: 0;
  font-size: 0.5rem;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.column button,
.collapse-button-col {
  background: #ccd1e4;
  height: 1rem;
  width: 1rem;
  text-align: center;
  padding: 0;
  font-size: 0.5rem;
  cursor: pointer;
  position: absolute;
  top: 0;
}
</style>
