<template>
  <div class="tree-selection-container">
    <div class="tree-selection">
      <button v-for="tree in orderedTrees" :key="tree.id"
              :class="{'tree-selector': true, 'active': tree.id === selectedTree}"
              @click="this.$emit('selectTree', tree.id)">
        Id: {{ tree.id }}
        <div class="tree-content">
          <div class="info">
            Performance metrics:
            <span>Accuracy: {{ (tree.metrics.accuracy * 100).toFixed(1) }}%</span>
            <span>F1: {{ (tree.metrics.f1 * 100).toFixed(1) }}%</span>
            <span>Precision: {{ (tree.metrics.precision * 100).toFixed(1) }}%</span>
            <span>Recall: {{ (tree.metrics.recall * 100).toFixed(1) }}%</span>
            <span>Internal nodes: {{ tree.metrics.n_nodes }}</span>

          </div>
          <div class="info">
            Used features:
            <span v-for="(feature, index) in tree.features" :key="index">{{ feature }}</span>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeSelector",
  props: ['possibleTrees', 'selectedTree', 'filter'],
  computed: {
    orderedTrees: function () {
      if (!this.filter) {
        return this.possibleTrees
      }
      if (this.filter === 'n_nodes') {
        return [...this.possibleTrees].sort((a, b) => {
          return a.metrics[this.filter] - b.metrics[this.filter]
        })
      }
      return [...this.possibleTrees].sort((a, b) => {
        return b.metrics[this.filter] - a.metrics[this.filter]
      })
    }
  }
}
</script>

<style scoped>
.tree-selection-container {
  width: 100%;
  height: 500px;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 1rem;
}

.tree-selection {
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.tree-selection::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tree-selector {
  appearance: none;
  width: 90%;
  border: 1px solid black;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  cursor: pointer;
  background: #CCD1E4;
}

.tree-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
}

.active {
  background: #FE7E6D;
}

</style>