<template>
  <div
    :class="{
      node: true,
      highlighted: highlightedBranch === content,
      tooltip: true,
    }"
    :id="id"
    :style="{
      backgroundColor: backgroundColor,
      color: color,
      cursor: cursor,
      opacity:
        collapsed !== undefined ? (collapsed ? 0.5 : 1) : active ? 1 : 0.5,
      visibility: id ? 'visible' : 'hidden',
    }"
  >
    {{ shownContent }}
    <span class="tooltiptext" v-if="content !== shownContent">{{
      content
    }}</span>
    <button
      v-if="descendantCount"
      @click="
        () => {
          if (this.clickHandler) {
            this.clickHandler();
            this.active = !this.active;
          }
        }
      "
    >
      {{ collapsed ? `+${descendantCount}` : '-'}}
    </button>
  </div>
</template>

<script>
export default {
  name: "TreeNode",
  props: {
    content: String,
    id: String,
    backgroundColor: String,
    color: String,
    cursor: String,
    clickHandler: Function,
    highlightedBranch: String,
    collapsed: Boolean,
    descendantCount: Number,
  },
  computed: {
    shownContent: function () {
      return this.content.length > 25
        ? this.content.slice(0, 20) + "..."
        : this.content;
    },
  },
  data() {
    return {
      active: true,
    };
  },
};
</script>

<style scoped>
.node {
  padding: 1rem;
  margin: 1rem;
  border: 1px solid black;
  /* border-radius: 50%; */
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  font-size: 0.7rem;
}

.highlighted {
  border: 5px dotted white;
  padding: 2rem;
  font-size: 1rem;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

button {
  border: 2px solid black;
  background: white;
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  padding: auto;
  border-radius: 50%;
}
</style>
