import { createApp } from 'vue'
import App from './App.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faEllipsisVertical, faEllipsis, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faEllipsisVertical, faEllipsis, faMinus, faPlus)

createApp(App).mount('#app')
